// Generated by Framer (b12dec4)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/yzxDjBFXU";

const cycleOrder = ["DlMuDF5tC"];

const variantClassNames = {DlMuDF5tC: "framer-v-vsal57"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const transformTemplate = (_, t) => `translate(-50%, -50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, j, width, ...props}) => { return {...props, XPmqtAEhv: j ?? props.XPmqtAEhv ?? "FAQ"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;j?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, XPmqtAEhv, LZP1jKnW6CfBTWnlgw, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "DlMuDF5tC", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-iA3Zu", sharedStyle.className, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-vsal57", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"DlMuDF5tC"} ref={ref} style={{...style}}><motion.div className={"framer-4lwwsy"} layoutDependency={layoutDependency} layoutId={"INXLqqqzY"} transformTemplate={transformTemplate}><RichText __fromCanvasComponent children={<React.Fragment><motion.p><Link href={LZP1jKnW6CfBTWnlgw} openInNewTab={false} smoothScroll><motion.a className={"framer-styles-preset-1s7y24g"} data-styles-preset={"yzxDjBFXU"}>Prk</motion.a></Link></motion.p></React.Fragment>} className={"framer-15oclez"} data-framer-name={"fghh"} layoutDependency={layoutDependency} layoutId={"aXnKZfPpX"} style={{"--framer-paragraph-spacing": "0px"}} tabIndex={0} text={XPmqtAEhv} verticalAlignment={"top"} withExternalLayout/></motion.div></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-iA3Zu [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-iA3Zu .framer-1jd053x { display: block; }", ".framer-iA3Zu .framer-vsal57 { height: 19px; overflow: visible; position: relative; width: 29px; }", ".framer-iA3Zu .framer-4lwwsy { align-content: center; align-items: center; display: flex; flex: none; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; left: 48%; overflow: visible; padding: 0px 0px 0px 0px; position: absolute; top: 47%; width: min-content; }", ".framer-iA3Zu .framer-15oclez { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-iA3Zu .framer-4lwwsy { gap: 0px; } .framer-iA3Zu .framer-4lwwsy > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-iA3Zu .framer-4lwwsy > :first-child { margin-top: 0px; } .framer-iA3Zu .framer-4lwwsy > :last-child { margin-bottom: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 19
 * @framerIntrinsicWidth 29
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"XPmqtAEhv":"j"}
 * @framerImmutableVariables false
 */
const FramerylhwLd9lP: React.ComponentType<Props> = withCSS(Component, css, "framer-iA3Zu") as typeof Component;
export default FramerylhwLd9lP;

FramerylhwLd9lP.displayName = "FAQ";

FramerylhwLd9lP.defaultProps = {height: 19, width: 29};

addPropertyControls(FramerylhwLd9lP, {XPmqtAEhv: {defaultValue: "FAQ", displayTextArea: true, placeholder: "jk", title: "j", type: ControlType.String}} as any)

addFonts(FramerylhwLd9lP, [...sharedStyle.fonts])